import { create } from 'zustand';

import { HTTP } from 'api/http';
import { IAuthData } from 'api/models/IAuth';
import { notificationError } from 'utils/index';

interface IAuthState {
    config: IAuthData | null;
    setConfig: (config: IAuthData | null) => void;
    logout: () => Promise<void>;
}

export const useAuthStore = create<IAuthState>((set) => ({
    config: null,
    setConfig: (config: IAuthData | null) => set({ config }),
    logout: async () => {
        try {
            await HTTP.post('auth/logout');
        } catch (error) {
            notificationError('Ошибка', error);
        } finally {
            set({ config: null });
        }
    },
}));
