import { Button, Result, Spin } from 'antd';
import { lazy, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, redirect, useNavigate, useRouteError } from 'react-router-dom';

import { HTTP } from 'api/http';
import { useAuthStore } from 'stores/authStore';

const checkAuth = async () => {
    const { config, setConfig } = useAuthStore.getState();
    if (!config) {
        try {
            const response = await HTTP.post('auth/config');
            setConfig(response.data);
            return response.data;
        } catch {
            return null;
        }
    }
    return config;
};

const loginLoader = async () => {
    const config = await checkAuth();
    return config ? redirect('/dashboard') : null;
};

const protectedLoader = async () => {
    const config = await checkAuth();
    return config ? null : redirect('/login');
};

const RootErrorBoundary = () => {
    const error = useRouteError() as Error | null;
    const navigate = useNavigate();

    const handleReload = () => {
        navigate('/');
    };

    return (
        <Result
            status="warning"
            title="Произошла ошибка, обратитесь в IT отдел."
            extra={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
                    <span className="text-red">{error ? error.message : 'Неизвестная ошибка'}</span>
                    <Button type="primary" onClick={handleReload}>
                        Перезагрузить
                    </Button>
                </div>
            }
        />
    );
};

const routes = [
    {
        path: '/dashboard',
        Component: lazy(() => import('./App')),
        errorElement: <RootErrorBoundary />,
        children: [
            { path: '', Component: lazy(() => import('./containers/Index')) },
            { path: 'users', Component: lazy(() => import('./containers/Users')) },
            { path: 'building', Component: lazy(() => import('./containers/Building')) },
            { path: 'buildings', Component: lazy(() => import('./containers/Buildings')) },
            { path: 'clients', Component: lazy(() => import('./containers/Clients')) },
            { path: 'requests', Component: lazy(() => import('./containers/Requests')) },
            { path: 'scheduler', Component: lazy(() => import('./containers/Scheduler')) },
            { path: 'telephony', Component: lazy(() => import('./containers/Telephony')) },
            { path: 'legal', Component: lazy(() => import('./containers/Legal')) },
            { path: 'calculator', Component: lazy(() => import('./containers/Calculator')) },
            { path: '*', Component: lazy(() => import('./containers/Pages/NotFound')) },
        ],
        loader: protectedLoader,
    },
    {
        path: '/login',
        Component: lazy(() => import('./containers/Pages/SignIn')),
        loader: loginLoader,
    },
    {
        path: '/403',
        Component: lazy(() => import('./containers/Pages/Forbidden')),
    },
    {
        path: '/',
        element: <Navigate to="/login" />,
    },
    {
        path: '*',
        element: <Navigate to="/login" />,
    },
];

const router = createBrowserRouter(routes);

const AppRoutes = () => (
    <Suspense fallback={<Spin tip="Загрузка" size="large" fullscreen />}>
        <RouterProvider router={router} />
    </Suspense>
);

export default AppRoutes;
