import { notification } from 'antd';
import axios from 'axios';
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import Funcs from './funcs';
import Vars from './vars';

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

export const notificationError = (message: string, error: unknown) => {
    console.error(error);
    notification.error({
        message,
        description: error instanceof Error ? error.message : 'Неизвестная ошибка',
    });
};

export const handleImageUpload = async ({ file, onSuccess, onError, url }: { file: any; onSuccess?: (response: any) => void; onError?: (error: Error) => void; url?: string }) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`/${url}/upload`, formData, {
            baseURL: Utils.Vars.HOST_UPLOADS,
            withCredentials: true,
        });

        if (response) {
            const data = response.data;
            if (onSuccess) onSuccess(data);
            notification.success({
                message: 'Фото успешно загружено',
                placement: 'bottomRight',
            });
        } else {
            throw new Error('Image upload failed');
        }
    } catch (error) {
        if (onError) onError(error as Error);
        notification.error({
            message: 'Фото не загружено',
            placement: 'bottomRight',
        });
    }
};

const Utils = { Funcs, Vars };

export default Utils;
